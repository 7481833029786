<template>
    <section>
        <modal ref="modalEditarCliente" :titulo="`Actualizar datos del ${$config.cliente}`" @guardar="editar_cliente">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 mt-1">
                    <div class="col-12">
                        <ValidationProvider v-slot="{errors}" rules="required|max:17" name="nombres">
                            <label class="f-15 pl-3"> Nombres </label>
                            <el-input v-model="model.prop_nombre" class="w-100" maxlength="17" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-1">
                        <ValidationProvider v-slot="{errors}" rules="required|max:18" name="apellidos">
                            <label class="f-15 pl-3"> Apellidos </label>
                            <el-input v-model="model.prop_apellido" class="w-100" maxlength="18" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-1">
                        <ValidationProvider v-slot="{errors}" rules="" vid="password" name="contraseña">
                            <label class="f-15 pl-3">Contraseña </label>
                            <el-input v-model="model.password" class="w-100" autocomplete="nope" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-1">
                        <ValidationProvider v-slot="{ errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                            <label class="f-15 pl-3">Confirmar contraseña </label>
                            <el-input v-model="model.password_confirmation" class="w-100" autocomplete="nope" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-1 relative">
                        <div v-if="!ilimitado" class="d-flex flex-column">
                            <label class="f-15 pl-3">Linea de credito </label>
                            <ValidationProvider v-slot="{ errors }" rules="required" name="Cupo del credito">
                                <Money v-model="model.cupo" class="input-money w-50" v-bind="money" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <template v-else>
                            <div class="d-flex flex-column">
                                <label class="f-15 pl-3">Linea de credito </label>
                                <el-input placeholder="Valor" disabled size="small" class="w-50" />
                            </div>
                        </template>
                        <div style="position: realtve;">
                            <div class="d-flex flex-column w-45 pr-2" style="position: absolute; top: 1px; right: 12px;">
                                <label class="f-15 pl-3">Periodo de pago (días)</label>
                                <el-select v-model="diasPago" size="small" class=" w-100" placeholder="Días">
                                    <el-option
                                    v-for="(item, idx) in diasPagoOpciones"
                                    :key="idx"
                                    :label="item"
                                    :value="item"
                                    />
                                </el-select>
                            </div>
                        </div>
                        <el-checkbox v-model="ilimitado" @change="!model.ilimitado == esIlimitado()">
                            Sin linea de crédito
                        </el-checkbox>
                    </div>
                    <div class="col-12 my-1" />
                    <div class="col-12 my-1">
                        <label class="f-15 pl-3 mx-2"> Fecha de nacimiento </label>
                        <el-date-picker
                        v-model="model.nacimiento"
                        type="date"
                        size="small"
                        value-format="yyyy-MM-dd"
                        format="dd MMM yyyy"
                        :clearable="false"
                        />
                    </div>
                    <div class="col-12 text-center mt-3">
                        <div class="d-middle-center my-3">
                            <label class="f-15 pl-3 mx-2"> Genero </label>
                            <el-radio-group v-model="model.genero" class="mx-2">
                                <el-radio class="radio-red" :label="3">
                                    Femenino
                                </el-radio>
                                <el-radio class="radio-red" :label="2">
                                    Masculino
                                </el-radio>
                                <el-radio class="radio-red" :label="1">
                                    Otro
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal-seleccionar-avatar ref="SelectAvatar" @avatar="elegir_avatar" />
        <mapa-google-modal
        ref="modalMapaGoogle"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
</template>
<script>
import Clientes from "../../../services/clientes";
import Localizaciones from "../../../services/proyectos/localizaciones";
import { mapGetters } from 'vuex'
import { Money } from 'v-money'

export default {
    components: {
        Money,
        modalSeleccionarAvatar: () => import('../partials/modalSeleccionarAvatar')
    },
    data(){
        return{
            disabled: true,
            ilimitado: false,
            paises: [],
            estados: [],
            ciudades: [],
            coordenadas: {
                lat: 0,
                lng: 0,
            },
            model:{
                prop_nombre: null,
                prop_apellido: null,
                genero: null,
                nacimiento: null,
                avatar: null,
                id_avatar: null,
                cupo: '',
                password: null,
                password_confirmation: null,
            },
            diasPago: 0,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            id: 0,
            diasPagoOpciones: [0,15,30,45,60,120,180]
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    methods: {
        async toggle({id}){
            this.id = id
            await this.show()
            // this.model = {...params}
            this.$refs.modalEditarCliente.toggle();
        },
        async show(){
            try {
                const { data } = await Clientes.show(this.id)
                this.model.prop_nombre = data.prop_nombre
                this.model.prop_apellido = data.prop_apellido
                this.model.genero = data.genero
                this.model.cupo = data.cupo_credito
                this.model.nacimiento = data.nacimiento
                this.model.avatar = data.avatar
                this.model.id_avatar = data.id_avatar
                this.model.id = this.id
                this.diasPago = Number(data.credito_dias)
                if(data.cupo_credito == '0'){
                    this.ilimitado = true
                }else {
                    this.ilimitado = false
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        seleccionarAvatar(){
            this.$refs.SelectAvatar.toggle(this.model.id_avatar)
        },
        esIlimitado(){
            if (this.model.ilimitado){
                return this.model.cupo = 0;
            }
        },
        async editar_cliente(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.diasPago = this.diasPago;
                if(this.ilimitado)this.model.ilimitado = true
                const {data} = await Clientes.editar_cliente(this.model)
                this.$validar(data)
                this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                this.$emit('listar')
                this.limpiar()
                this.$refs.modalEditarCliente.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                prop_nombre: null,
                prop_apellido: null,
                genero: null,
                nacimiento: null,
                avatar: null,
                id_avatar: null,
                password_confirmation: null,
                password: null,
            }
            this.id = 0
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarEstados(){
            try {
                this.model.idm_estado = null
                this.model.idm_ciudad = null
                this.estados = []
                this.ciudades = []
                const {data} = await Localizaciones.consultarEstados({
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.model.idm_pais
                })

                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudades(){
            try {
                this.model.idm_ciudad = null
                this.ciudades = []
                const {data} = await Localizaciones.consultarCiudades({
                    proyecto: this.$usuario.proyecto.id,
                    estado: this.model.idm_estado
                })
                this.model.idm_ciudad = null
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            console.log(latitud,longitud);
            this.model.latitud = latitud
            this.model.longitud = longitud
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
        },
        elegir_avatar(avatar){
            this.model.avatar = avatar.imagen_firmada
            this.model.id_avatar = avatar.id
            console.log('avatar',avatar);
        }
    }
}
</script>
